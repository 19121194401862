import React from 'react';
import { ProviderContainer, ProviderButton, RemoveIcon, ResetButton } from './SelectedProviders.style';

// Инициализируем массив провайдеров прямо здесь
const defaultProviders = ['Все провайдеры', 'Bgaming'];

const SelectedProviders = ({ 
  selectedProviders = defaultProviders, // По умолчанию используем defaultProviders, если ничего не передано
  onRemoveProvider, 
  onResetProviders, 
  language
}) => {
  return (
    <ProviderContainer>
      {selectedProviders.map((provider) => (
        <ProviderButton key={provider}>
          {provider}
          <RemoveIcon src="/Wallet-Page/close-circle.svg" alt="Удалить" onClick={() => onRemoveProvider(provider)} />
        </ProviderButton>
      ))}
      {selectedProviders.length > 0 && (
        <ResetButton onClick={() => onResetProviders([...defaultProviders])}>
          {language === 'ru' ? 'Сбросить всё' : 'Reset All'}
        </ResetButton>
      )}
    </ProviderContainer>
  );
};

export default SelectedProviders;
