import React, { useState, useEffect } from 'react';
import {
  BalanceBarContainer,
  IconContainer,
  WalletButtonContainer,
  NavItem,
  NavText,
  BalanceAmount,
  Icon,
  WalletButton,
  WalletIcon,
} from './BalanceBar.styles';
import { getUserBalance } from '../../services/userService';
import { useNavigate, useLocation  } from 'react-router-dom';

const BalanceBar = ({ initDataRaw, language }) => {
  const [balance, setBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(true); 
  const [activeTab, setActiveTab] = useState(''); 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchBalance = async () => {
      setIsLoading(true);

      if (!initDataRaw) {
        setBalance(0);
        setIsLoading(false);
        return;
      }

      try {
        const response = await getUserBalance(initDataRaw);
        const fetchedBalance = parseFloat(response.balance);
        if (isNaN(fetchedBalance)) {
          setBalance(0); 
        } else {
          setBalance(fetchedBalance); 
        }
      } catch (error) {
        console.error("Ошибка при загрузке баланса:", error);
        setBalance(0); 
      } finally {
        setIsLoading(false); 
      }
    };

    fetchBalance();
  }, [initDataRaw]);

  useEffect(() => {
    switch (location.pathname) {
      case '/search':
        setActiveTab('search');
        break;
      case '/history':
        setActiveTab('history');
        break;
      case '/favorites':
        setActiveTab('favorites');
        break;
      case '/tournaments':
        setActiveTab('tournaments');
        break;
      default:
        setActiveTab('');
        break;
    }
  }, [location.pathname]);

  const handleWalletNavigation = () => {
    navigate('/wallet');
  };

  const handleSearchNavigation = () => {
    navigate('/search', { state: { balance } });
  };

  const handleHistoryNavigation = () => {
    navigate('/history');
  };

  const handleFavoritesNavigation = () => {
    navigate('/favorites');
  };

  const handleTournamentsNavigation = () => {
    navigate('/tournaments');
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName); 
  };

  return (
    <BalanceBarContainer>
      <IconContainer>
        <NavItem onClick={() => { handleSearchNavigation(); handleTabClick('search'); }}>
          <Icon src={activeTab === 'search' ? "/Main-Page/BalanceBar/searchActive.svg" : "/Main-Page/BalanceBar/SearchIcon.svg"} alt="Поиск" />
          <NavText $isActive={activeTab === 'search'}>{language === 'ru' ? 'Поиск' : 'Search'}</NavText>
        </NavItem>
        <NavItem onClick={() => { handleTournamentsNavigation(); handleTabClick('tournaments'); }}>
          <Icon src={activeTab === 'tournaments' ? "/Main-Page/BalanceBar/turActive.svg" : "/Main-Page/BalanceBar/tur.svg"} alt="Турниры" />
          <NavText $isActive={activeTab === 'tournaments'}>{language === 'ru' ? 'Турниры' : 'Tournaments'}</NavText>
        </NavItem>
        
      </IconContainer>

      <WalletButtonContainer>
        <WalletButton onClick={handleWalletNavigation}>
          <WalletIcon src="/Main-Page/BalanceBar/walletIcon.png" alt="Wallet" />
        </WalletButton>
        {isLoading ? ( 
          <BalanceAmount>{language === 'ru' ? 'Загрузка...' : 'Loading...'}</BalanceAmount>
        ) : (
          <BalanceAmount>{(balance / 100).toFixed(2)}$</BalanceAmount>
        )}
      </WalletButtonContainer>

      <IconContainer>
        <NavItem onClick={() => { handleFavoritesNavigation(); handleTabClick('favorites'); }}>
          <Icon src={activeTab === 'favorites' ? "/Main-Page/BalanceBar/likeActive.svg" : "/Main-Page/BalanceBar/like.svg"} alt="Избранное" />
          <NavText $isActive={activeTab === 'favorites'}>{language === 'ru' ? 'Избранное' : 'Favorites'}</NavText>
        </NavItem>
        <NavItem onClick={() => { handleHistoryNavigation(); handleTabClick('history'); }}>
          <Icon src={activeTab === 'history' ? "/Main-Page/BalanceBar/userActive.svg" : "/Main-Page/BalanceBar/user.svg"} alt="Профиль" />
          <NavText $isActive={activeTab === 'history'}>{language === 'ru' ? 'Профиль' : 'Profile'}</NavText>
        </NavItem>
      </IconContainer>
    </BalanceBarContainer>
  );
};

export default BalanceBar;
