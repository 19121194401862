import styled from 'styled-components';

export const WalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vh;
  position: relative;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
    linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  color: white;
  min-height: 100vh;
  width: 100%;
`;

export const DropdownContainer = styled.div`
  width: 90%;
  background: #2e2e42;
  margin-top: 5px;
  border-radius: 10px;
  padding: 10px 0;
`;

export const DropdownOption = styled.div`
  padding: 10px 15px;
  font-size: 14px;
  color: white;
  font-family: 'Rounded Mplus 1c', sans-serif;
  cursor: pointer;

  &:hover {
    background-color: #444;
  }
`;

export const IconDropdown = styled.img`
  width: 20px;
  height: 20px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  margin-bottom: 3vh;
  margin-top: 0vh;
  margin: 0, 3vh;
  position: relative; 
  background-color: #2e2e42; 
  border-radius: 10px; 
`;

export const TabButton = styled.button`
  background-color: ${({ active }) => (active ? '#ffffff' : 'transparent')}; 
  color: ${({ active }) => (active ? '#000000' : '#ffffff')};
  font-size: 16px;
  height: 40px; 
  border: none;
  cursor: pointer;
  flex: 1;
  position: relative;
  z-index: ${({ active }) => (active ? 2 : 1)}; 
  border-radius: 10px; 
  margin: 0; 

  ${({ active }) => active && `
    box-shadow: 0px 4px 0px 0px #0088cc; /* Blue shadow to create underline effect */
  `}

  transition: background-color 0.1s ease, color 0.3s ease, box-shadow 0.3s ease;
`;

export const WalletConnectRow = styled.div`
  display: flex;
  height:  7vh;
  align-items: center;
  justify-content: space-between;
  width: 90%; 
  padding: 1.5vh; 
  border-radius: 14px; 
  background-color: rgba(255, 255, 255, 0.1); 
  border: 1px solid rgba(255, 255, 255, 0.2); 
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    border-color: rgba(255, 255, 255, 0.3); 
  }

  &:focus-within {
    border-color: #0088cc; 
  }
`;



export const WalletConnectText = styled.div`
  display: flex;
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  align-items: center;
  font-size: 14px;
  color: white;
`;

export const IconLeft = styled.img`
  width: 4vh;
  height: 4vh;
  margin-right: 2vh;
  padding: 0vh; 
`;

export const IconRight = styled.img`
  width: 4vh;
  height: 4vh;
  padding: 0vh; 
`;

export const TopLabel = styled.div`
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Rounded Mplus 1c', sans-serif;
  color: #FFF;
  margin-bottom: 1vh;
`;

export const InputContainer = styled.div`
  height:  7vh;
  margin: 3vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 2vh;
  font-weight: 400;
  font-family: 'Rounded Mplus 1c', sans-serif;
  width: 90%;
  font-size: 14px;
  color: white;
`;

export const Input = styled.input`

  padding: 1.5vh; 
  font-size: 16px; 
  font-weight: 400;
  font-family: 'Rounded Mplus 1c', sans-serif;
  width: 90%;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.2); 
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.1); 
  color: white;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5); 
    font-size: 16px;
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    border-color: #0088cc; 
  }

  @layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const AmountOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  width: 90%;
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  button {
    background-color: #1e2636;
    color: white;
    padding: 0.8vh 1vh;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 18%;
    text-align: center;
    position: relative; 

    box-shadow: 0px 2px 0px 0px rgba(59, 100, 248, 1); 
    
    transition: scale 0.2s ease;
    &:hover {
      scale: 1.1; 
    }
  }
`;

export const MinAmountLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: 'Rounded Mplus 1c', sans-serif;
  color: rgba(255, 255, 255, 0.6); 
  margin-top: 1vh;
  width: 90%;
`;



export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 4vh; 
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  align-items: center;
  width: 100%;
`;

export const ButtonTonText = styled.button`
  padding: 1.5vh 0vh;  
  font-size: 16px;
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  background-color: #ffffff;  
  color: #000000; 
  border: none;
  border-radius: 10px; 
  cursor: pointer;
  width: 90%; 
  box-shadow: 0vh 0.5vh 0vh 0vh rgba(59, 100, 248, 1), 0vh 0.5vh 1vh 0.5vh rgba(59, 100, 248, 0.5); 

  &:hover {
    background-color: #f0f0f0;  
  }

  &:active {
    background-color: #e0e0e0;  
  }

  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
`;

export const TransactionHistoryButton = styled.button`
  padding: 1.5vh 0vh;  
  font-size: 16px;
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  background-color: rgba(255, 255, 255, 0.1);  
  color: white; 
  border: none;
  border-radius: 10px; 
  cursor: pointer;
  width: 90%; 


  &:active {
    background-color: rgba(255, 255, 255, 0.3);  
  }

  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
`;

export const BonusImage = styled.img`
  margin-top: 2vh;
  width: 90%;
  height: auto;
  border-radius: 10px;
`;
