import styled from 'styled-components';

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
              linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  padding: 3vh;
  padding-bottom: 3vh; 
  height: 100vh;
  width: 100%;
  position: relative;
  box-sizing: border-box; 
  overflow: hidden; 
`;

export const GameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;

export const GameTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2vh;
  margin-bottom:1vh;
  width: 90%;
`;

export const GameTitle = styled.h2`
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
  color: white;
  margin: 0;
`;

export const GameProvider = styled.p`
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: white;
  opacity: 0.6;
  margin-top: 0.5vh;
  margin-bottom: 3vh;
`;

export const GameImage = styled.img`
  width: 90%;
  height: 60vh;
  border-radius: 12px;
  margin-bottom:
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 2vh;
  justify-content: center; 
  width: 100%; 
  margin-top: auto;
  margin-bottom: 2vh;
`;

export const DemoButton = styled.button`
  flex: 1;
  width: 48%; 
  padding: 1.8vh 2vh;
  background: rgba(255, 255, 255, 0.06);
  color: white;
  border: none;
  border-radius: 12px;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const PlayButton = styled(DemoButton)`
  background-color: #ffffff; 
  color: black;
  box-shadow: 0 0.4vh 0 0 rgba(59, 100, 248, 1); 
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
  margin-bottom: 2vh;
`;


export const LikeButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0vh 0.2vh 0vh 0vh rgba(59, 100, 248, 1);
  width: 7vh;
  height: 7vh;
  background: #FFFFFF0F;
  align-self: flex-start; 
  margin-top: 0; 
`;

export const LikeIcon = styled.div`
  width: 3vh;
  height: 3vh;
  background: ${({ isLiked }) => isLiked ? 'url("/Game-Page/likeActive.svg")' : 'url("/Game-Page/like.svg")'} no-repeat center center;
  background-size: contain;
`;


export const IframeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black; 
  z-index: 9999; 
  
  iframe {
    width: 100%;
    height: 100%;
  }
`;
