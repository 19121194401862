import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  GameContainer,
  GameImage,
  GameTitle,
  GameProvider,
  ButtonContainer,
  DemoButton,
  PlayButton,
  ErrorMessage,
  LikeButton,
  LikeIcon,
  GameTitleContainer,
  IframeContainer,
} from './Game.styles';
import { getUserBalance, startGameSession, addToFavorites, removeFromFavorites, getFavoriteGames, startGameDemo } from '../../services/userService';
import Loader from '../Loader/Loader'; 

const GamePage = ({ initDataRaw, language }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(null);
  const [gameUrl, setGameUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const { gameIdentifier, gameImage, gameName, providerName } = location.state;

  const [error, setError] = useState('');
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await getUserBalance(initDataRaw);
        setBalance(response.balance);
      } catch (error) {
      }
    };

    fetchBalance();
  }, [initDataRaw]);

  useEffect(() => {
    const checkIfFavorite = async () => {
      try {
        const favoriteGames = await getFavoriteGames(initDataRaw, providerName);
        const isGameFavorite = favoriteGames.some(game => game.game_id === gameIdentifier);
        setIsLiked(isGameFavorite);
      } catch (error) {

      }
    };

    checkIfFavorite();
  }, [initDataRaw, gameIdentifier, providerName]);

  const handleStartGame = async (isDemo) => {
    setIsLoading(true); 
    try {
      let gameUrl;
      
      if (!isDemo) {
        gameUrl = await startGameSession(gameIdentifier, initDataRaw, balance, language, gameImage, gameName);
      } else {
        gameUrl = await startGameDemo(gameIdentifier, initDataRaw, language, gameImage, gameName);
      }
      
      setGameUrl(gameUrl);
    } catch (error) {
      const errorMessage = language === 'ru' ? 'Ошибка при создании игровой сессии' : 'Error starting game session';
      setError(errorMessage);

    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(-1);
    });


    return () => {
      window.Telegram.WebApp.BackButton.hide();
    };
  }, [navigate]);

  const handleLike = async () => {
    if (isLiked) {
      await removeFromFavorites(initDataRaw, gameIdentifier);
      setIsLiked(false);
    } else {
      await addToFavorites(initDataRaw, gameIdentifier, providerName, gameName, gameImage);
      setIsLiked(true);
    }
  };

  return (
    <GameContainer>
      {isLoading ? ( 
        <Loader />
      ) : gameUrl ? ( 
        <IframeContainer>
          <iframe src={gameUrl} title="Game" frameBorder="0" allowFullScreen />
        </IframeContainer>
      ) : (
        <>
          <GameTitleContainer>
            <div>
              <GameTitle>{gameName}</GameTitle>
              <GameProvider>{providerName}</GameProvider>
            </div>
            <LikeButton onClick={handleLike}>
              <LikeIcon isLiked={isLiked} />
            </LikeButton>
          </GameTitleContainer>
          
          <GameImage src={`${process.env.REACT_APP_BACKEND_URL}${gameImage}`} alt={gameName} />
          
          {error && <ErrorMessage>{error}</ErrorMessage>}
          
          <ButtonContainer>
            <DemoButton onClick={() => handleStartGame(true)}>
              {language === 'ru' ? 'Демо режим' : 'Demo Mode'}
            </DemoButton>
            <PlayButton onClick={() => handleStartGame(false)}>
              {language === 'ru' ? 'Играть' : 'Play'}
            </PlayButton>
          </ButtonContainer>
        </>
      )}
    </GameContainer>
  );
};

export default GamePage;
