import React from 'react';
import './Loader.scss'; 
const CenteredLoader = () => {
  return (
    <div className="centered-loader">
      <div className="Loader">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="cube"></div>
        ))}
      </div>
    </div>
  );
};

export default CenteredLoader;
