import React, { useState, useEffect } from 'react';
import { HistoryContainer, ScrollContent, GameGrid, SectionTitle, GameCard, GameImage, NoGamesMessage, ProvidersButton, ProvidersIcon, SelectedProvidersCount } from './PopularGames.styles';
import { useNavigate } from 'react-router-dom';
import { getPopularGames } from '../../services/userService'; 
import TabsNavigation from '../TabsNavigation/TabsNavigation'; 
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader'; 
import SelectedProviders from '../SelectedProviders/SelectedProviders'; 

const PopularGames = ({ initDataRaw, language }) => {
  const [popularGames, setPopularGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [selectedProviders, setSelectedProviders] = useState([]); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllProviders = () => {
      const providersFromStorage = JSON.parse(localStorage.getItem('allProviders'));
      if (providersFromStorage) {
        setSelectedProviders(providersFromStorage);
      }
    };

    fetchAllProviders();
  }, []);

  useEffect(() => {
    const fetchSelectedProviders = () => {
      const savedProviders = JSON.parse(localStorage.getItem('selectedProviders'));
      if (savedProviders && savedProviders.length > 0) {
        setSelectedProviders(savedProviders); 
      }
      setIsLoading(false); 
    };

    fetchSelectedProviders();
  }, []);

  useEffect(() => {
    const fetchPopularGames = async () => {
      setIsLoading(true); 
      if (selectedProviders.length > 0) {
        const games = await getPopularGames(initDataRaw, selectedProviders);
        setPopularGames(games);
      } else {
        setPopularGames([]); 
      }
      setIsLoading(false);
    };

    fetchPopularGames();
  }, [initDataRaw, selectedProviders]);

  const handleStartGame = (game) => {
    navigate('/game', { 
      state: { 
        gameIdentifier: game.game_id,
        gameImage: game.src,
        gameName: game.name,
        providerName: game.provider
      } 
    });
  };

  const handleProvidersClick = () => {
    navigate('/providers');
  };

  const handleRemoveProvider = (provider) => {
    const updatedProviders = selectedProviders.filter(p => p !== provider);
    setSelectedProviders(updatedProviders);
    localStorage.setItem('selectedProviders', JSON.stringify(updatedProviders));
    if(updatedProviders.length ==0){
      const allProviders = JSON.parse(localStorage.getItem('allProviders')) || [];
      setSelectedProviders(allProviders);
      localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
    }
  };

  const handleResetProviders = () => {
    const allProviders = JSON.parse(localStorage.getItem('allProviders')) || [];
    setSelectedProviders(allProviders);
    localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
  };

  const renderGames = (games) => {
    return games.map((game) => (
      <GameCard key={game.id} onClick={() => handleStartGame(game)}>
        <GameImage
          src={`${process.env.REACT_APP_BACKEND_URL}${game.src}` || '/Search-Page/random.png'}
          alt={game.name || 'Game'}
        />
      </GameCard>
    ));
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <ScrollContent>
      <HistoryContainer>
        <TabsNavigation language={language} />
        
        <ProvidersButton onClick={handleProvidersClick}>
          {language === 'ru' ? 'Все провайдеры' : 'All Providers'}
          <ProvidersIcon src='/History-Page/arrow-down.svg' alt="Arrow" /> 
          {selectedProviders.length > 0 && (
            <SelectedProvidersCount>{selectedProviders.length}</SelectedProvidersCount>
          )}
        </ProvidersButton>
        
        {selectedProviders.length > 0 && (
          <SelectedProviders
            selectedProviders={selectedProviders}
            onRemoveProvider={handleRemoveProvider}
            onResetProviders={handleResetProviders}
            language={language}
          />
        )}

        <SectionTitle>{language === 'ru' ? 'Популярные' : 'Popular Games'}</SectionTitle>
        

        <GameGrid>
          {popularGames && popularGames.length > 0 ? renderGames(popularGames) : 
            <NoGamesMessage>
              {language === 'ru' ? 'Пока ничего нет' : 'No popular games available'}
              <div>{language === 'ru' ? 'Тут будут популярные игры' : 'Popular games will be here'}</div>
            </NoGamesMessage>
          }
        </GameGrid>

        <Footer language={language} />
      </HistoryContainer>
    </ScrollContent>
  );
};

export default PopularGames;
