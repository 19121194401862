import styled from 'styled-components';

export const ScrollContent = styled.div`
  padding: 0;
  overflow-y: auto;
  flex-grow: 1;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;

  /* Скрываем полосу прокрутки */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar {
    display: none;  /* WebKit */
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%);
  color: white;
  min-height: 100vh;
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 800;
  font-family: 'Rounded Mplus 1c', sans-serif;
  color: white;
  text-align: left;
  margin-left: 5%;
  margin-bottom: 2vh;
`;

export const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vh;
  width: 90%;
  margin: 0 auto;
`;

export const GameCard = styled.div`
  background-color: #121429;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative; 
  &:hover {
    transform: scale(1.05);
  }
`;

export const GameImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

export const NoGamesMessage = styled.div`
  grid-column: span 3;
  grid-row: span 3;
  background: #FFFFFF0F;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  height: calc(100vw / 3 * 3);
  padding: 2vh;

  div {
    margin-top: 1vh;
    font-family: 'Rounded Mplus 1c', sans-serif;
    opacity: 0.6;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
`;

export const ProvidersButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5vh 2vh;
  background: #FFFFFF0F;
  width: 90%;
  color: white;
  border: none;
  border-radius: 12px;
  font-family: 'Rounded Mplus 1c';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
  gap: 1vh; 
  margin: 0 auto; 
  margin-top: 1.5vh;
    position: relative;
  &:hover {
    background-color: #272B3F;
  }
`;

export const ProvidersIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 1vh;
`;

export const SelectedProvidersCount = styled.div`
  background-color: #E20000;
  color: white;
  border-radius: 50%;
  width: 3.2vh;
  height: 3.2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  position: absolute; 
  top: -0.8vh; 
  right: -0.8vh;
`;
