import styled from 'styled-components';

export const ProviderContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 2vh;
  margin-left: 5%;
  overflow-x: auto; 
  white-space: nowrap; 
  padding-bottom: 1vh;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const ProviderButton = styled.div`
  display: flex; 
  align-items: center;
  padding: 1vh 2vh;
  padding-right: 1vh;
  background-color: #ffffff;
  color: rgba(13, 16, 22, 1);
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  border: none;
  border-radius: 8px; 
  cursor: pointer;
  box-shadow: 0 0 5px rgba(59, 100, 248, 0.3);
  white-space: nowrap;
  transition: padding-right 0.3s ease, background-color 0.3s ease, color 0.3s ease;
`;

export const RemoveIcon = styled.img`
  width: 3vh;
  height: 3vh;
  margin-left: 0.5vh;
  cursor: pointer;
`;

export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.8vh 2vh;
  background-color: rgba(255, 255, 255, 0.06);
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: padding-right 0.3s ease, background-color 0.3s ease, color 0.3s ease;
`;
