import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  width: 100%;
  color: white; 
  
`;

export const Title = styled.h1` 
font-size: 2.5rem;
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  margin-bottom: 10vh;
`