import React, { useEffect, useRef } from 'react';
import { 
  DeletionPopUpContainer, 
  DeletionPopUpHeader, 
  DeletionPopUpTitle, 
  DoneButton, 
  WalletDeleteOption, 
  WalletDeleteText, 
  WalletDeleteIcon,
  WalletDeleteTrashIcon
} from './WalletDeletionPopup.styles'; 
import { useTonConnectUI } from '@tonconnect/ui-react'; 

const WalletDeletionPopup = ({ wallets, language, onClose }) => {
  const [tonConnectUI] = useTonConnectUI();
  const popupRef = useRef(null); 

  const handleDeleteWallet = async () => {
    try {
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error('Error while deleting wallet:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside); 

    return () => {
      document.removeEventListener('mousedown', handleClickOutside); 
    };
  }, [onClose]);

  return (
    <DeletionPopUpContainer ref={popupRef}> 
      <DoneButton onClick={onClose}>{language === 'ru' ? 'Готово' : 'Done'}</DoneButton>
      <DeletionPopUpHeader>
        <DeletionPopUpTitle>{language === 'ru' ? 'Удаление кошельков' : 'Removing wallets'}</DeletionPopUpTitle>
      </DeletionPopUpHeader>

      {wallets.map((wallet, index) => (
        <WalletDeleteOption key={index}>
          <WalletDeleteIcon src="/Wallet-Page/Deposit/Usdt.png" alt="USDT Icon" /> 
          <WalletDeleteText>{wallet.name} {wallet.shortenedAddress}</WalletDeleteText>
          <WalletDeleteTrashIcon 
            src="/Wallet-Page/Deposit/trash.png" 
            alt="Delete Wallet" 
            onClick={handleDeleteWallet} 
          />
        </WalletDeleteOption>
      ))}
    </DeletionPopUpContainer>
  );
};

export default WalletDeletionPopup;
