import React from 'react';
import {
  FooterContainer,
  FooterLogo,
  SupportButtons,
  SupportButton,
  SupportIcon,
  FooterText,
  ArrowIcon,
  DocumentLinks,
  DocumentLink,
} from './Footer.styles';

const Footer = ({ language }) => {
  return (
    <FooterContainer>
      <FooterLogo src="/Main-Page/Footer/logo.png" alt="Beton Logo" />
      <SupportButtons>
        <SupportButton>
          <SupportIcon src="/Main-Page/Footer/chatImg.png" alt="Online Chat" />
          <div>
            <p>{language === 'ru' ? 'Online чат' : 'Online Chat'}</p>
            <span>{language === 'ru' ? 'На связи 24/7' : 'In touch 24/7'}</span>
          </div>
        </SupportButton>
        <SupportButton>
          <SupportIcon src="/Main-Page/Footer/supportImg.png" alt="Support" />
          <div>
            <p>{language === 'ru' ? 'Поддержка' : 'Support'}</p>
            <span>support@betton</span>
          </div>
        </SupportButton>
      </SupportButtons>

      <DocumentLinks>
        <DocumentLink href="#">
        {language === 'ru' ? 'Политика конфиденциальности' : 'Privacy Policy'}
          <ArrowIcon src="/Main-Page/Footer/urlImg.png" alt="Arrow" />
        </DocumentLink>
        <DocumentLink href="#">
        {language === 'ru' ? 'Условия использования' : 'Terms of Use'}
          <ArrowIcon src="/Main-Page/Footer/urlImg.png" alt="Arrow" />
        </DocumentLink>
        <DocumentLink href="#">
        {language === 'ru' ? 'Политика безопасности игроков' : 'Player Safety Policy'}
          <ArrowIcon src="/Main-Page/Footer/urlImg.png" alt="Arrow" />
        </DocumentLink>
      </DocumentLinks>

      <FooterText>
      {language === 'ru' ? '© 2024 Все права защищены. BETTON принадлежит и управляется компанией --------, юридический адрес:' : '© 2024 All rights reserved. BETTON is owned and operated by --------, registered address:'}
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
