import React, { useState, useEffect } from 'react';
import { ModalOverlay, ModalContainer, ModalContent, ModalMessage, ModalButton, Icon, RemainingWagerContainer, RemainingWagerAmount, RemainingWagerText } from './SuccessModal.style';
import {  getRemainingWagering } from '../../../services/userService'; 
const SuccessModal = ({ onClose, type, language }) => {
  const isDeposit = type === 'deposit';

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalContent>
          <Icon src="/Wallet-Page/success.png" alt="Success" />
          <ModalMessage>
            <h2>
              {isDeposit
                ? language === 'ru' ? 'Успешно!' : 'Success!'
                : language === 'ru' ? 'Вывод успешен!' : 'Withdrawal successful!'}
            </h2>
            <p>
              {isDeposit
                ? language === 'ru'
                  ? 'Оплата прошла успешно, средства уже на вашем балансе.'
                  : 'Payment was successful, funds are already in your balance.'
                : language === 'ru'
                  ? 'Вывод средств прошел успешно, средства списаны с вашего баланса.'
                  : 'Withdrawal was successful, funds have been deducted from your balance.'}
            </p>
          </ModalMessage>
          <ModalButton onClick={onClose}>
            {isDeposit
              ? language === 'ru' ? 'Играть' : 'Play'
              : language === 'ru' ? 'Закрыть' : 'Close'}
          </ModalButton>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

const ErrorModal = ({ onRetry, type, language }) => {
   const isDeposit = type === 'deposit'

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalContent>
          <Icon src="/Wallet-Page/error.png" alt="Error" />
          <ModalMessage>
            <h2>
              {isDeposit
                ? language === 'ru' ? 'Что-то пошло не так' : 'Something went wrong'
                : language === 'ru' ? 'Ошибка при выводе' : 'Withdrawal error'}
            </h2>
            <p>
              {isDeposit
                ? language === 'ru'
                  ? 'При оплате произошла ошибка, можете попробовать ещё раз.'
                  : 'There was an error during payment, you can try again.'
                : language === 'ru'
                  ? 'При выводе средств произошла ошибка, попробуйте снова.'
                  : 'There was an error during the withdrawal, please try again.'}
            </p>
          </ModalMessage>
          <ModalButton onClick={onRetry}>
            {isDeposit
              ? language === 'ru' ? 'Попробовать ещё раз' : 'Try again'
              : language === 'ru' ? 'Повторить вывод' : 'Retry withdrawal'}
          </ModalButton>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};


const WageringModal = ({ onRetry, language, initDataRaw }) => {
  const [remainingWager, setRemainingWager] = useState(0);

  useEffect(() => {
    const fetchRemainingWager = async () => {
      const remaining = await getRemainingWagering(initDataRaw)/100; 
      setRemainingWager(remaining);
    };

    fetchRemainingWager();
  }, []);

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalContent>
          <ModalMessage>
            <h2 style={{ fontSize: '22px' }}>
              {language === 'ru' ? 'Вывод недоступен' : 'Error during withdrawal'}
            </h2>
            <p>
             {language === 'ru'
                  ? 'Чтобы совершить вывод, вам необходимо отыграть сумму в 2 раза больше от суммы первого депозита.'
                  : 'You have not met the wagering requirements for withdrawal. Please complete the wagering to proceed.'}
            </p>
          </ModalMessage>

          <RemainingWagerContainer>
            <RemainingWagerAmount>
              {remainingWager} USDT
            </RemainingWagerAmount>
            <RemainingWagerText>{language === 'ru' ? 'Осталось получить' : 'Remaining to wager'}</RemainingWagerText>
          </RemainingWagerContainer>

          <ModalButton onClick={onRetry}>
            {language === 'ru' ? 'Продолжить играть' : 'Try again'}
          </ModalButton>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};


export { SuccessModal, ErrorModal, WageringModal };
