const serverUrl = process.env.REACT_APP_BACKEND_URL;

const fetchWithAuth = async (url, options = {}, initData) => {
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${initData}`,
    ...options.headers,
  };

  return fetch(url, { ...options, headers });
};

const getUserLanguage = async (initData) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/language`, {}, initData);
  const data = await response.json();
  return data.languageCode;
};
const getUserBalance = async (initData) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/balance`, {}, initData);
  return await response.json();
};

const verifyTransaction = async (boc, senderAddress, initData, amount, transactionType) => {
  try {
    const response = await fetchWithAuth(`${serverUrl}/api/transactions/verify-transaction`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ boc, senderAddress, amount, transactionType }),
    }, initData);

    if (!response.ok) {
      throw new Error('Ошибка верификации транзакции');
    }

    return await response.json();
  } catch (error) {
    console.error('Ошибка при верификации транзакции:', error);
    return { success: false, message: error.message };
  }
};



const getMinDeposit = async (initData) => {
  const response = await fetchWithAuth(`${serverUrl}/api/adminSettings/min-deposit`, {}, initData);
  const data = await response.json();
  return data.minDeposit;
};

const startGameSession = async (gameIdentifier, initData, balance, language, src, name) => {
  const provider = 'Bgaming';

  try {
    const response = await fetchWithAuth(`${serverUrl}/sessions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ gameIdentifier, balance, language, provider, src, name }),
    }, initData);

    const data = await response.json();
    return data.gameUrl;
  } catch (error) {
    throw new Error('Не удалось начать игровую сессию');
  }
};
;

const startGameDemo = async (gameIdentifier, initData, language, src, name) => {
  const provider = 'Bgaming';

  try {
    const response = await fetchWithAuth(`${serverUrl}/demo`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ gameIdentifier, language, provider, src, name }),
    }, initData);

    const data = await response.json();
    return data.gameUrl;
  } catch (error) {
    throw new Error('Не удалось начать демо-сессию');
  }
};


const getRemainingWagering = async (initData) => {
  const response = await fetchWithAuth(`${serverUrl}/api/adminSettings/get-wagering`, {}, initData);
  const data = await response.json();
  return data.remainingWagering;
};


const getHistoryGames = async (initData, provider) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/history?provider=${encodeURIComponent(provider)}`, {}, initData);
  return await response.json();
};


const getFavoriteGames = async (initData) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/favoriteGames`, {}, initData);
  return await response.json();
};


const addToFavorites = async (initData, gameId, provider, name, src) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/favorites`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ gameId, provider, name, src }),
  }, initData);
  return await response.json();
};



const removeFromFavorites = async (initData, gameId) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/favorites/${gameId}`, {
    method: 'DELETE',
  }, initData);
  return await response.json();
};

const getNewGames = async (initData, provider) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/newGames/${provider}`, {}, initData);
  return await response.json();
};


const getPopularGames = async (initData, provider) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/popularGames/${provider}`, {}, initData);
  return await response.json();
};

const getAllGames = async (initData) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/allGames`, {}, initData);
  return await response.json();
};

const getTransactions = async (initData, filter) => {
  const queryParam = filter !== 'all' ? `?type=${filter}` : '';
  const response = await fetchWithAuth(`${serverUrl}/api/transactions/history${queryParam}`, {}, initData);
  return await response.json();
};


export { 
  getUserLanguage, 
  getUserBalance, 
  startGameSession,
  startGameDemo,
  getMinDeposit,
  getRemainingWagering,
  getHistoryGames,  
  removeFromFavorites,
  addToFavorites,
  getFavoriteGames,
  getNewGames,
  getPopularGames,
  getAllGames,
  getTransactions,
  verifyTransaction
};
