import styled from 'styled-components';

export const BalanceBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  background: linear-gradient(99.31deg, rgba(13, 16, 22, 0.8) -9.79%, rgba(17, 30, 54, 0.8) 20.84%, rgba(13, 16, 22, 0.8) 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.8) 5.44%, rgba(13, 16, 22, 0) 93.15%);
  padding: 1vh 3vh;
  backdrop-filter: blur(2vh);
  z-index: 100;
  box-sizing: border-box;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 40%; 
  gap: 2vh;
`;

export const WalletButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10vh; 
  flex-shrink: 0; 
`;
export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vh;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const NavText = styled.p`
  color: ${({ isActive }) => (isActive ? 'rgba(59, 100, 248, 1)' : 'white')}; 
  font-size: 10px;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-weight: 500;
  padding-top: 0;
  margin: 0;
`;

export const BalanceAmount = styled.p`
  color: white;
  margin-top: 1vh; 
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 6vh;
`;

export const Icon = styled.img`
  width: 4vh;
  height: 4vh;
  margin-bottom: 0;
`;

export const WalletButton = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  width: 8vh; 
  height: 8vh;
  cursor: pointer;
  border-radius: 50%;
  background: linear-gradient(99.31deg, rgba(13, 16, 22, 0.8) -9.79%, rgba(17, 30, 54, 0.8) 20.84%, rgba(13, 16, 22, 0.8) 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.6) 5.44%, rgba(13, 16, 22, 0) 93.15%);
  margin-bottom: 0.2vh;
  position: relative;
  box-shadow: 0vh 0.4vh 0vh 0vh rgba(59, 248, 112, 1);
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

export const WalletIcon = styled.img`
  width: 4vh;
  height: 4vh;
  object-fit: cover;
  margin: 0;
  padding: 0;
`;
