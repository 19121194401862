import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer, ProviderContainer, ScrollContent, SectionTitle, ProvidersList, ProviderItem, CheckboxWrapper, CheckboxInput, CheckboxIcon, ProviderLabel, ActionButtons, Button } from './Provider.styles';
import Loader from '../Loader/Loader'; 

const ProviderSelector = ({ setSelectedProviders, language }) => {
    const navigate = useNavigate();
    
    const allProviders = language === 'ru' ? ['Все провайдеры', 'Bgaming'] : ['All Providers', 'Bgaming']; 
    const realProviders = allProviders.filter(p => p !== (language === 'ru' ? 'Все провайдеры' : 'All Providers')); 
    const [providers, setProviders] = useState(allProviders); 
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const savedProviders = localStorage.getItem('selectedProviders');
      if (savedProviders) {
        const parsedProviders = JSON.parse(savedProviders);
        
        if (realProviders.every(provider => parsedProviders.includes(provider))) {
          setProviders(allProviders);
        } else {
          setProviders(parsedProviders);
        }
      } else {
        localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
      }
      localStorage.setItem('allProviders', JSON.stringify(realProviders));
      setIsLoading(false);
    }, [language]); 
    useEffect(() => {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate(-1);
      });

      return () => {
        window.Telegram.WebApp.BackButton.hide();
      };
    }, [navigate]);

    const toggleProvider = (providerName) => {
      let updatedProviders;

      if (providerName === (language === 'ru' ? 'Все провайдеры' : 'All Providers')) {
        updatedProviders = allProviders; 
      } else if (providers.includes(providerName)) {
        updatedProviders = providers.filter((name) => name !== providerName); 
        if (updatedProviders.length === 0) {
          updatedProviders = allProviders; 
        }
      } else {
        updatedProviders = providers.filter((name) => name !== (language === 'ru' ? 'Все провайдеры' : 'All Providers')); 
        updatedProviders.push(providerName); 
      }

      setProviders(updatedProviders);
    };

    const isChecked = (providerName) => providers.includes(providerName);

    const handleSave = () => {
      let finalSelectedProviders;
      if (providers.includes(language === 'ru' ? 'Все провайдеры' : 'All Providers')) {
        finalSelectedProviders = realProviders;
      } else {
        finalSelectedProviders = providers;
      }
      
      localStorage.setItem('selectedProviders', JSON.stringify(finalSelectedProviders)); 
      setSelectedProviders(finalSelectedProviders);  
      navigate(-1); 
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <MainContainer>
        <ProviderContainer>
          <ScrollContent>
            <SectionTitle>{language === 'ru' ? 'Провайдеры' : 'Providers'}</SectionTitle>
            <ProvidersList>
              {allProviders.map((provider) => (
                <ProviderItem key={provider}>
                  <CheckboxWrapper>
                    <CheckboxInput
                      type="checkbox"
                      checked={isChecked(provider)}
                      onChange={() => toggleProvider(provider)}
                    />
                    <CheckboxIcon checked={isChecked(provider)} />
                  </CheckboxWrapper>
                  <ProviderLabel onClick={() => toggleProvider(provider)}>
                    {provider}
                  </ProviderLabel>
                </ProviderItem>
              ))}
            </ProvidersList>
            <ActionButtons>
              <Button onClick={() => setProviders(allProviders)}>{language === 'ru' ? 'Сбросить' : 'Reset'}</Button>
              <Button primary onClick={handleSave}>{language === 'ru' ? 'Показать' : 'Show'}</Button>
            </ActionButtons>
          </ScrollContent>
        </ProviderContainer>
      </MainContainer>
    );
};

export default ProviderSelector;
