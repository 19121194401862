import styled from 'styled-components';

export const DeletionPopUpContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  right: 0;
  height: 40vh;
  background-color: #1E2434;
  border-radius: 20px 20px 0 0;
  padding: 3vh;
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const DeletionPopUpHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeletionPopUpTitle = styled.h3`
  color: #ffffff;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-weight: 700;
`;

export const DoneButton = styled.span`
  position: absolute;
  font-size: 12px;
  top: 2vh;
  right: 4vh;
  color: #FFF;
  font-family: 'Rounded Mplus 1c', sans-serif;
  cursor: pointer;
`;

export const WalletDeleteOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  position: relative; /* Make it relative to position the trash icon inside it */
`;

export const WalletDeleteText = styled.span`
  font-size: 16px;
  margin-left: 2vh;
`;

export const WalletDeleteIcon = styled.img`
  width: 4vh;
  height: 4vh;
  margin-right: 2vh;
`;

export const WalletDeleteTrashIcon = styled.img`
  width: 3vh;
  height: 3vh;
  cursor: pointer;
  position: absolute;
  right: 0; 
  top: 50%;
  transform: translateY(-50%); 
`;
