
import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(13, 16, 22, 0.8); 
  z-index: 9; 
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
    linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  padding: 20px;
  border-radius: 24px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  width: 80%;
  text-align: center;
  z-index: 10; 
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img`
  width: 50vh;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  
  &[alt="Error"] {
    width: 26vh; 
  }
`;


export const ModalMessage = styled.div`

  h2 {
    font-family: 'Rounded Mplus 1c', sans-serif;
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
    color: #ffffff;
    margin: 0;
  }

  p {
    font-family: 'Rounded Mplus 1c', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #b0b3c0;
    margin-top: 8px;
  }
`;

export const ModalButton = styled.button`
  padding: 2.5vh 0vh;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0vh 0.5vh 0vh 0vh rgba(59, 100, 248, 1), 0vh 0.5vh 1vh 0.5vh rgba(59, 100, 248, 0.5);

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #e0e0e0;
  }

  transition: background-color 0.3s ease, box-shadow 0.3s ease;
`;

export const RemainingWagerContainer = styled.div`
  background: rgba(255, 255, 255, 0.06);
  border-radius: 24px;
  padding: 0.2vh 3vh;
  margin-bottom: 3vh;
  width:90%;
  text-align: center;
`;

export const RemainingWagerAmount = styled.div`
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: center;
  margin-top: 3vh;
  color: #ffffff;
`;

export const RemainingWagerText = styled.p`
font-family: 'Rounded Mplus 1c', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 16px;
text-align: center;
margin-top: 1vh;
color: rgba(255, 255, 255, 0.6);

`
