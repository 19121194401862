import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HistoryContainer,
  ScrollContent,
  TransactionListContainer,
  TransactionItem,
  TransactionDetails,
  TransactionAmount,
  FilterButton,
  TabsContainer,
  TransactionIcon,
  TransactionDate,
  CloseIcon,
  Title
} from './TransactionHistory.styles';
import { getTransactions } from '../../../services/userService';

const TransactionHistory = ({ initDataRaw, language }) => {
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState('');
  const [fadedTransactionIndex, setFadedTransactionIndex] = useState(-1);
  const navigate = useNavigate();
  const listContainerRef = useRef(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const userTransactions = await getTransactions(initDataRaw, filter);
        const sortedTransactions = userTransactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setTransactions(sortedTransactions);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTransactions();
  }, [initDataRaw, filter]);

  const handleFilterChange = (newFilter) => {
    setFilter((prevFilter) => (prevFilter === newFilter ? '' : newFilter));
  };

  const handleScroll = () => {
    if (listContainerRef.current) {
      const scrollTop = listContainerRef.current.scrollTop;
      setFadedTransactionIndex(scrollTop > 50 ? 0 : -1); 
    }
  };

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(-1);
    });

    return () => {
      window.Telegram.WebApp.BackButton.hide();
    };
  }, [navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} • ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  return (
    <ScrollContent>
      <HistoryContainer>
        <Title>{language === 'ru' ? 'История операций' : 'Transaction History'}</Title>
        <TabsContainer>
          <FilterButton active={filter === 'Deposit'} onClick={() => handleFilterChange('Deposit')}>
            {language === 'ru' ? 'Только депозиты' : 'Only Deposits'}
            {filter === 'Deposit' && (
              <CloseIcon
                src="/Wallet-Page/close-circle.svg"
                alt="Close"
                onClick={() => handleFilterChange('')}
              />
            )}
          </FilterButton>
          <FilterButton active={filter === 'withdrawal'} onClick={() => handleFilterChange('withdrawal')}>
            {language === 'ru' ? 'Только выводы' : 'Only Withdrawals'}
            {filter === 'withdrawal' && (
              <CloseIcon
                src="/Wallet-Page/close-circle.svg"
                alt="Close"
                onClick={() => handleFilterChange('')}
              />
            )}
          </FilterButton>
        </TabsContainer>

        <TransactionListContainer ref={listContainerRef} onScroll={handleScroll}>
          {transactions.map((transaction, index) => (
            <TransactionItem key={transaction.id} isFaded={index === fadedTransactionIndex}>
              <TransactionIcon
                src={transaction.transaction_type === 'Deposit' ? '/Wallet-Page/receive.svg' : '/Wallet-Page/send.svg'}
                alt={transaction.transaction_type === 'Deposit' ? 'Deposit' : 'Withdrawal'}
              />
              <TransactionDetails>
                <TransactionAmount isPositive={transaction.transaction_type === 'Deposit'}>
                  {transaction.transaction_type === 'Deposit' ? '+' : '-'} {transaction.amount/100} USDT
                </TransactionAmount>
                <TransactionDate>{formatDate(transaction.created_at)}</TransactionDate>
              </TransactionDetails>
            </TransactionItem>
          ))}
        </TransactionListContainer>
      </HistoryContainer>
    </ScrollContent>
  );
};

export default TransactionHistory;
