import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  color: white;
  overflow: hidden;
  padding: 0;
  position: relative; 
`;


export const FixedBackground = styled.div`
  width: 100%;
  height: 35vh;
  background: url('/Main-Page/Main-Images/header.png');
  background-size: cover;
  background-position: center;
  position: relative;
`;

export const ScrollContent = styled.div`
  padding: 0;
  overflow-y: scroll;
  flex-grow: 1;
  z-index: 2;
  width: 100%; /* Полная ширина */
  box-sizing: border-box;
  
  /* Скрываем полосу прокрутки */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar {
    display: none;  /* WebKit */
  }
`;

export const SectionTitle = styled.h2`
  margin: 0;
  margin-top: 1vh
  margin-bottom: 2vh;
  font-size: 2rem;
  font-weight: 800;
  font-family: 'Rounded Mplus 1c', sans-serif;
  color: white;
  text-align: left;
  padding-left: 0vh;
`;

export const GradientGrid = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 3vh;
`;

export const GameGrid = styled.div` 
  margin-top: 2vh;
  display: grid;
  padding: 1vh 1vh;
  grid-template-rows: repeat(3, 1fr); 
  grid-auto-flow: column; 
  grid-auto-columns: 31.5%; 
  gap: 1.5vh;
  width: 100%;
  overflow-x: auto; 
  overflow-y: hidden; 
  box-sizing: border-box;

  scrollbar-width: none;
  -ms-overflow-style: none;  
  
  &::-webkit-scrollbar {
    display: none; 
  }
`;


export const GameCard = styled.div`
  background-color: #121429;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  transition: transform 0.3s ease;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
  }
`;

export const GameImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 3/4;
  object-fit: contain; 
  border-radius: 10px;
`;



export const BalanceContainer = styled.div`
  margin-top: 20px;
`;

export const WhiteBarWithDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 0.7vh;
  margin: 0 auto;
  margin-top: 1.5vh;
  margin-bottom: 5vh;
`;
