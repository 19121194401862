import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  position: relative;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
    linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  color: white;
  height: 100vh; 
  width: 100%;
  overflow: hidden; 
`;

export const ProviderContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3vh;
`;

export const ScrollContent = styled.div`
  padding: 0;
  flex-grow: 1;
  overflow-y: auto; 
  z-index: 2;
  width: 100%; 
  box-sizing: border-box;
  
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar {
    display: none;  /* WebKit */
  }
`;

export const SectionTitle = styled.label`
  display: block;
  position: absolute;
  
  top: 2vh;
  left: 5%;
  
  font-weight: 800;
  font-family: 'Rounded Mplus 1c', sans-serif;
  width: 90%;
  font-size: 22px;
  color: white;
`;

export const ProvidersList = styled.div`
  height: 80vh;
  margin: 3vh 0;
  margin-top: 7vh;
  display: flex;
  flex-direction: column;
`;

export const ProviderItem = styled.div`
  display: flex;
  align-items: center;
  padding: 2vh 0;
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const CheckboxIcon = styled.div`
  width: 3.5vh;
  height: 3.5vh;
  background-image: url(${({ checked }) => (checked ? '/Provider-Page/ChekYes.svg' : '/Provider-Page/ChekNo.svg')});
  background-size: cover;
  background-repeat: no-repeat;

`;

export const ProviderLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  color: white;
  margin-left: 2vh;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1vh;
  position: absolute;
  bottom: 3vh; 
  align-items: center;
`;

export const Button = styled.button`
  background-color: ${({ primary }) => (primary ? '#ffffff' : 'rgba(255, 255, 255, 0.06)')}; 
  opacity: 1;
  color: ${({ primary }) => (primary ? 'black' : '#FFF')};
  font-size: 16px;
  height: 7vh; 
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  border: none;
  cursor: pointer;
  flex: 1;
  border-radius: 15px; 
  box-shadow: ${({ primary }) => (primary ? '0px 2px 0px 0px #3B64F8' : 'none')};
  transition: all 0.3s ease;
  &:hover {
    scale: 1.05;
  }
`;
