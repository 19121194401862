import React, { useState, useEffect } from 'react';
import { THEME, TonConnectUIProvider, useTonConnectUI, useTonAddress, useTonConnectModal } from '@tonconnect/ui-react';
import { 
  WalletContainer, 
  Input, 
  InputContainer, 
  InputLabel, 
  ButtonTonText, 
  TabsContainer, 
  TabButton, 
  AmountOptions, 
  WalletConnectRow, 
  WalletConnectText, 
  MinAmountLabel,
  IconLeft, 
  IconRight, 
  TopLabel,
  ButtonContainer, 
  TransactionHistoryButton 
} from './Wallet.styles';
import PaymentMethodPopup from './PopUps/PaymentMethodsPopUp';
import { verifyTransaction, getMinDeposit, getRemainingWagering } from '../../services/userService'; 
import { withdrawFunds, sendTonTransaction } from '../../services/tonServices'; 
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { SuccessModal, ErrorModal, WageringModal } from './Status/SuccessModal';

const handleAmountInput = (value, setValue) => {
  const regex = /^\d*\.?\d{0,2}$/; 
  if (regex.test(value)) {
    setValue(value);
  }
};

const Wallet = ({ initDataRaw, language }) => {
  const [amount, setAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [minDeposit, setMinDeposit] = useState(1.62); 
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonAddress();
  const { open } = useTonConnectModal();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('deposit');
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false); 
  const [showErrorModal, setShowErrorModal] = useState(false); 
  const [showWageringModal, setShowWageringModal] = useState(false); 
  const [modalType, setModalType] = useState('deposit'); 

  useEffect(() => {
    const fetchMinDeposit = async () => {
      try {
        if (!initDataRaw) {
          setMinDeposit(0); 
          return;
        }
        const deposit = await getMinDeposit(initDataRaw);
        setMinDeposit(deposit);
      } catch (error) {
        console.error('Ошибка при получении минимального депозита:', error);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchMinDeposit();
  }, [initDataRaw]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(-1); 
    });

    return () => {
      window.Telegram.WebApp.BackButton.hide();
    };
  }, [navigate]);

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const handleConnectWallet = async () => {
    tonConnectUI.setConnectRequestParameters({
      state: 'ready',
      items: [{ name: 'ton_addr' }]
    });
    open();
  };
  
  const handleSendTransaction = async () => {
    if (!wallet) {
      await handleConnectWallet();
      return;
    }

    try {
      const transaction = await sendTonTransaction(wallet, amount, 'Пополнение баланса', initDataRaw);

      if (!transaction?.messages) {
        throw new Error('Некорректная структура транзакции');
      }

      const result = await tonConnectUI.sendTransaction({
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: transaction.messages.map((msg) => ({
          address: msg.to,
          amount: msg.value,
          payload: msg.payload,
          bounce: true,
        })),
        keepOpened: true,
      });

      const verifyResponse = await verifyTransaction(
        result.boc, 
        wallet,
        initDataRaw,
        amount,
        'Deposit'
      );

      if (verifyResponse?.success) {
        setModalType('deposit');
        setShowSuccessModal(true);
      } 
    } catch (error) {
      setModalType('deposit');
      setShowErrorModal(true);
    }
  };

  const handleWithdraw = async () => {
    if (!wallet) {
      handleConnectWallet();
      return;
    }

    if (!withdrawAmount || parseFloat(withdrawAmount) <= 0) {
      return;
    }

    try {
      const remainingWagering = await getRemainingWagering(initDataRaw) / 100;
      if (remainingWagering > 0) {
        setShowWageringModal(true);
        return;
      }

      const amountToSend = parseFloat(withdrawAmount).toString();
      await withdrawFunds(wallet, amountToSend, 'Withdrawal from Casino', initDataRaw);

      setModalType('withdraw'); 
      setShowSuccessModal(true);
    } catch (error) {
      setModalType('withdraw'); 
      setShowErrorModal(true);
    }
  };

  const handleTabClick = async (tab) => {
    if (tab === 'withdraw') {
      const remainingWagering = await getRemainingWagering(initDataRaw) / 100;
      if (remainingWagering > 0) {
        setShowWageringModal(true); 
        return;
      }
    }
    setActiveTab(tab);
  };

  const togglePopUp = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };

  const handleTransactionHistory = () => {
    navigate('/transaction-history');
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <TonConnectUIProvider
      manifestUrl="https://betongame.club/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
    >
      <WalletContainer>
        <TabsContainer>
          <TabButton active={activeTab === 'deposit'} isFirst onClick={() => handleTabClick('deposit')}>
            {language === 'ru' ? 'Пополнение' : 'Deposit'}
          </TabButton>
          <TabButton active={activeTab === 'withdraw'} onClick={() => handleTabClick('withdraw')}>
            {language === 'ru' ? 'Вывод' : 'Withdraw'}
          </TabButton>
        </TabsContainer>

        {activeTab === 'deposit' ? (
          <TopLabel>{language === 'ru' ? 'Способ пополнения' : 'Payment Method'}</TopLabel>
        ) : (
          <TopLabel>{language === 'ru' ? 'Сумма вывода' : 'Withdraw Details'}</TopLabel>
        )}

        <WalletConnectRow walletConnected={!!wallet} onClick={!wallet ? handleConnectWallet : togglePopUp}>
          <WalletConnectText>
            <IconLeft src="/Wallet-Page/Deposit/plus.png" alt="+" />
            {wallet ? shortenAddress(wallet) : language === 'ru' ? 'Привязать кошелёк' : 'Connect Wallet'}
          </WalletConnectText>
          <IconRight src="/Wallet-Page/Deposit/back.png" alt=">" />
        </WalletConnectRow>

        {activeTab === 'deposit' && (
          <>
            <InputContainer>
              <InputLabel>{language === 'ru' ? 'Сумма пополнения' : 'Deposit Amount'}</InputLabel>
              <Input
                type="number"
                value={amount}
                onChange={(e) => handleAmountInput(e.target.value, setAmount)}
                placeholder={language === 'ru' ? '0.00' : '0.00'}
              />
              <MinAmountLabel>{language === 'ru' ? `Мин. сумма: ${minDeposit / 100}` : `Min. amount: ${minDeposit / 100}`}</MinAmountLabel>
              <AmountOptions>
                {[50, 100, 150, 200, 1500].map((option) => (
                  <button key={option} onClick={() => setAmount(option)}>
                    {option}
                  </button>
                ))}
              </AmountOptions>

              <ButtonContainer>
                <TransactionHistoryButton onClick={handleTransactionHistory}>
                  {language === 'ru' ? 'История операций' : 'Transaction History'}
                </TransactionHistoryButton>
                <ButtonTonText onClick={handleSendTransaction} disabled={!amount}>
                  {language === 'ru' ? 'Пополнить' : 'Deposit'}
                </ButtonTonText>
              </ButtonContainer>
            </InputContainer>
          </>
        )}

        {activeTab === 'withdraw' && (
          <>
            <InputContainer>
              <InputLabel>{language === 'ru' ? 'Сумма вывода' : 'Withdraw Amount'}</InputLabel>
              <Input
                type="number"
                value={withdrawAmount}
                onChange={(e) => handleAmountInput(e.target.value, setWithdrawAmount)}
                placeholder={language === 'ru' ? '0.00' : '0.00'}
              />
              <ButtonContainer> 
                <ButtonTonText onClick={handleWithdraw}>
                  {language === 'ru' ? 'Вывести' : 'Withdraw'}
                </ButtonTonText>
              </ButtonContainer>
            </InputContainer>
          </>
        )}

        {isPopUpOpen && wallet && (
          <PaymentMethodPopup
            language={language}
            activeTab={activeTab}
            wallet={{
              name: 'Tether (TON)',
              shortenedAddress: shortenAddress(wallet),
              address: wallet,
              language,
            }}
            onClose={togglePopUp}
          />
        )}

        {showSuccessModal && <SuccessModal type={modalType} language={language} onClose={() => setShowSuccessModal(false)} />}
        {showErrorModal && <ErrorModal type={modalType} language={language} onRetry={() => setShowErrorModal(false)} />}
        {showWageringModal && <WageringModal initDataRaw={initDataRaw} language={language} onRetry={() => setShowWageringModal(false)} />}
      </WalletContainer>
    </TonConnectUIProvider>
  );
};

export default Wallet;
