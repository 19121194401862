import React, { useState, useEffect, useRef } from 'react';
import { 
  PopUpContainer, 
  PopUpHeader, 
  PopUpTitle, 
  ChangeText, 
  WalletOption, 
  WalletText, 
  WalletRadioButton, 
  ContinueButton,
  WalletIcon,
  Overlay
} from './PaymentMethodPopup.styles'; 
import WalletDeletionPopup from './WalletDeletionPopup'; 

const PaymentMethodPopup = ({ wallet, activeTab, language, onClose }) => {
  const [isChecked, setIsChecked] = useState(true);
  const [isDeletionPopupOpen, setIsDeletionPopupOpen] = useState(false); 
  const popupRef = useRef(null); 

  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleOpenDeletionPopup = () => {
    setIsDeletionPopupOpen(true);  
  };

  const handleCloseDeletionPopup = () => {
    setIsDeletionPopupOpen(false); 
    onClose();  
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); 
    };
  }, [onClose]);

  return (
    <>
      <Overlay />

      {!isDeletionPopupOpen && (
        <PopUpContainer ref={popupRef}> 
            <ChangeText onClick={handleOpenDeletionPopup}>{language === 'ru' ? 'Изменить' : 'Change'}</ChangeText> 
                <PopUpHeader>{activeTab === 'deposit' ? (
                    <PopUpTitle>{language === 'ru' ? 'Способы пополнения' : 'Payment Method'}</PopUpTitle>
                    ) : (
                    <PopUpTitle>{language === 'ru' ? 'Способы вывода' : 'Withdraw Method'}</PopUpTitle>
                    )}
                </PopUpHeader>

          <WalletOption onClick={toggleChecked}>
            <WalletIcon src="/Wallet-Page/Deposit/Usdt.png" alt="USDT Icon" /> 
            <WalletText>{wallet.name} {wallet.shortenedAddress}</WalletText>
            <WalletRadioButton checked={isChecked} />
          </WalletOption>

          <ContinueButton onClick={onClose}>{language === 'ru' ? 'Продолжить' : 'Continue '}</ContinueButton>
        </PopUpContainer>
      )}

      {isDeletionPopupOpen && (
        <WalletDeletionPopup 
          wallets={[wallet]} 
          language={language}
          onClose={handleCloseDeletionPopup}
        />
      )}
    </>
  );
};

export default PaymentMethodPopup;
