import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13, 16, 22, 0.8);
  z-index: 999;
`;

export const PopUpContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
    linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  height: 40vh;
  border-radius: 20px 20px 0 0;
  padding: 3vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const PopUpHeader = styled.div`
  margin-top: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const PopUpTitle = styled.h3`
  margin-top: 0vh;
  color: #ffffff;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-weight: 700;
`;

export const ChangeText = styled.span`
  position: absolute;
  font-size: 12px;
  top: 2vh;
  right: 4vh;
  color: #FFF;
  font-family: 'Rounded Mplus 1c', sans-serif;
  cursor: pointer;
`;

export const WalletText = styled.span`
  font-size: 16px;
  margin-left: 2vh;
`;

export const WalletOption = styled.div`
  display: flex;
  align-items: center;
  padding: 2vh 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  cursor: pointer;
  position: relative;
`;

export const WalletRadioButton = styled.div`
  width: 3vh;
  height: 3vh;
  background-image: ${({ checked }) =>
    checked
      ? "url('/Wallet-Page/Deposit/radio-checked.png')"
      : "url('/Wallet-Page/Deposit/radio-unchecked.png')"};
  background-size: cover;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const ContinueButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-top: auto;
  box-shadow: 0vh 0.5vh 0vh 0vh rgba(59, 100, 248, 1); 


`;

export const WalletIcon = styled.img`
  width: 4vh;
  height: 4vh;
`;
