import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { TabsContainer, TabsWrapper, Tab, Icon } from './TabsNavigation.styles';

const TabsNavigation = ({ language }) => {
  const activeTabRef = useRef(null);

  useEffect(() => {
    if (activeTabRef.current) {
      activeTabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center', 
      });
    }
  }, [activeTabRef]);

  return (
    <TabsWrapper>
      <TabsContainer>
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          {({ isActive }) => (
            <Tab ref={isActive ? activeTabRef : null} isActive={isActive}>
              {language === 'ru' ? 'Все игры' : 'All Games'}
            </Tab>
          )}
        </NavLink>
        <NavLink to="/popularGames" style={{ textDecoration: 'none' }}>
          {({ isActive }) => (
            <Tab ref={isActive ? activeTabRef : null} isActive={isActive}>
              {language === 'ru' ? 'Популярные' : 'Popular'}
            </Tab>
          )}
        </NavLink>
        <NavLink to="/newgames" style={{ textDecoration: 'none' }}>
          {({ isActive }) => (
            <Tab ref={isActive ? activeTabRef : null} isActive={isActive}>
              {language === 'ru' ? 'Новинки' : 'New'}
            </Tab>
          )}
        </NavLink>
        <NavLink to="/history" style={{ textDecoration: 'none' }}>
          {({ isActive }) => (
            <Tab ref={isActive ? activeTabRef : null} isActive={isActive}>
              <Icon src={isActive ? '/Tabs/historyActive.svg' : '/Tabs/history.svg'} alt="История" />{' '}
              {language === 'ru' ? 'История' : 'History'}
            </Tab>
          )}
        </NavLink>
        <NavLink to="/favorites" style={{ textDecoration: 'none' }}>
          {({ isActive }) => (
            <Tab ref={isActive ? activeTabRef : null} isActive={isActive}>
              <Icon src={isActive ? '/Tabs/likeActive.svg' : '/Tabs/like.svg'} alt="Избранное" />{' '}
              {language === 'ru' ? 'Избранное' : 'Favorites'}
            </Tab>
          )}
        </NavLink>
      </TabsContainer>
    </TabsWrapper>
  );
};

export default TabsNavigation;
