import styled from 'styled-components';

export const ScrollContent = styled.div`
  padding: 0;
  flex-grow: 1;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;

  /* Hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HistoryContainer = styled.div`
  padding: 2vh 5%;
  background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
    linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: flex-start; 
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  margin-bottom: 2vh;
  color: white;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
  align-self: flex-start; 
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start; 
  margin: 2vh 0;
  width: 100%; 
  gap: 1vh; 
`;

export const FilterButton = styled.button`
  display: flex; 
  align-items: center;
  padding: 1vh 2vh;
  padding-right: ${({ active }) => (active ? '4vh' : '2vh')};
  background-color: ${({ active }) => (active ? '#ffffff' : 'rgba(255, 255, 255, 0.06)')};
  color: ${({ active }) => (active ? '#000000' : 'white')};
  border: none;
  border-radius: 8px; 
  cursor: pointer;
  text-align: center;
  box-shadow: ${({ active }) => (active ? '0 0 10px rgba(59, 100, 248, 0.5)' : 'none')};
  position: relative; 
  white-space: nowrap;
  transition: padding-right 0.3s ease, background-color 0.3s ease, color 0.3s ease; 
`;

export const CloseIcon = styled.img`
  width: 3vh;
  height: 3vh;
  cursor: pointer;
  position: absolute;
  right: 0.5vh; 
`;

export const TransactionListContainer = styled.div`
  flex-grow: 1;
  max-height: 90vh; /* Ограничение высоты для списка транзакций */
  overflow-y: auto;
  width: 100%;
  padding-right: 1vh;
  position: relative;

  /* Скрываем скролл */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Блюр вверху при прокрутке */
  &::before {
    content: '';
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background: linear-gradient(rgba(0, 0, 0, 0.6), transparent);
    pointer-events: none;
    z-index: 1;
  }
`;

export const TransactionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 2vh 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: opacity 0.3s ease;
  opacity: ${({ isFaded }) => (isFaded ? 0.6 : 1)};
`;

export const TransactionIcon = styled.img`
  width: 4vh;
  height: 4vh;
  margin-right: 2vh;
`;

export const TransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const TransactionAmount = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: 'Rounded Mplus 1c', sans-serif;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

export const TransactionDate = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  text-align: right;
  white-space: nowrap;
  margin-left: auto;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;
