import styled from 'styled-components';

export const TabsWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  padding: 1vh 0;

  /* Чтобы горизонтальная прокрутка работала корректно */
  width: 100%;
  display: block;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; 
  scrollbar-width: none;
`;

export const TabsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1vh;
  padding: 0 2vh;
  width: fit-content; /* Настройка для прокрутки, если элементы выходят за границы */
`;

export const Icon = styled.img`
  width: 3vh;
  height: 3vh;
`;

export const Tab = styled.div`
  padding: 1vh 2vh;
  border-radius: 8px;
  background-color: ${({ isActive }) => (isActive ? '#ffffff' : '#1e2535')};
  color: ${({ isActive }) => (isActive ? '#000000' : 'white')};
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5vh;
  text-decoration: none;
  white-space: nowrap; /* Убедимся, что текст не переносится на новую строку */
  box-shadow: ${({ isActive }) => (isActive ? '0vh 0.3vh 0vh 0vh rgba(59, 100, 248, 1), 0vh 0.5vh 1vh 0.5vh rgba(59, 100, 248, 0.1)' : 'none')};
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;
