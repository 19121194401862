import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  HistoryContainer, 
  ScrollContent, 
  GameGrid, 
  SectionTitle, 
  GameCard, 
  GameImage, 
  NoGamesMessage, 
  ProvidersButton, 
  ProvidersIcon, 
  SelectedProvidersCount 
} from './NewGames.styles';
import { getNewGames } from '../../services/userService'; 
import TabsNavigation from '../TabsNavigation/TabsNavigation'; 
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader'; 
import SelectedProviders from '../SelectedProviders/SelectedProviders'; 

const NewGames = ({ initDataRaw, language }) => {
  const [newGames, setNewGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [selectedProviders, setSelectedProviders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllProviders = () => {
      const providersFromStorage = JSON.parse(localStorage.getItem('allProviders'));
      if (providersFromStorage) {
        setSelectedProviders(providersFromStorage);
      }
    };

    fetchAllProviders();
  }, []);

  useEffect(() => {
    const fetchSelectedProviders = () => {
      const savedProviders = JSON.parse(localStorage.getItem('selectedProviders'));
      if (savedProviders && savedProviders.length > 0) {
        setSelectedProviders(savedProviders); 
      }
      setIsLoading(false); 
    };

    fetchSelectedProviders();
  }, []);

  useEffect(() => {
    const fetchNewGames = async () => {
      setIsLoading(true); 
      if (selectedProviders.length > 0) {
        const games = await getNewGames(initDataRaw, selectedProviders);
        setNewGames(games);
      } else {
        setNewGames([]);
      }
      setIsLoading(false);
    };
  
    fetchNewGames();
  }, [initDataRaw, selectedProviders]);

  const handleStartGame = (game) => {
    navigate('/game', { 
      state: { 
        gameIdentifier: game.game_id, 
        gameImage: game.src,
        gameName: game.name,
        providerName: game.provider 
      } 
    });
  };

  const handleProvidersClick = () => {
    navigate('/providers');
  };

  const handleRemoveProvider = (provider) => {
    const updatedProviders = selectedProviders.filter(p => p !== provider);
    setSelectedProviders(updatedProviders);
    localStorage.setItem('selectedProviders', JSON.stringify(updatedProviders));
    if(updatedProviders.length ==0){
      const allProviders = JSON.parse(localStorage.getItem('allProviders')) || [];
      setSelectedProviders(allProviders);
      localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
    }
  };

  const handleResetProviders = () => {
    const allProviders = JSON.parse(localStorage.getItem('allProviders')) || [];
    setSelectedProviders(allProviders);
    localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
  };

  const renderGames = (games) => {
    return games.map((game) => (
      <GameCard key={game.id} onClick={() => handleStartGame(game)}>
        <GameImage
          src={`${process.env.REACT_APP_BACKEND_URL}${game.src}` || '/path/to/default-image.png'}
          alt={game.name || 'Game'} 
        />
      </GameCard>
    ));
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <ScrollContent>
      <HistoryContainer>
        <TabsNavigation language={language} />
        
        <ProvidersButton onClick={handleProvidersClick}>
          {language === 'ru' ? 'Все провайдеры' : 'All Providers'}
          {selectedProviders.length > 0 && (
            <SelectedProvidersCount>{selectedProviders.length}</SelectedProvidersCount>
          )}
          <ProvidersIcon src='/History-Page/arrow-down.svg' alt="Arrow" /> 
        </ProvidersButton>
        
        {selectedProviders.length > 0 && (
          <SelectedProviders
            selectedProviders={selectedProviders}
            onRemoveProvider={handleRemoveProvider}
            onResetProviders={handleResetProviders}
            language={language}
          />
        )}

        <SectionTitle>{language === 'ru' ? 'Новинки' : 'New Games'}</SectionTitle>
        
        <GameGrid>
          {newGames.length > 0 ? renderGames(newGames) : 
            <NoGamesMessage>
              {language === 'ru' ? 'Пока ничего нет' : 'No new games available'}
              <div>{language === 'ru' ? 'Тут будут новые игры' : 'New games will be here'}</div>
            </NoGamesMessage>
          }
        </GameGrid>

        <Footer language={language} />
      </HistoryContainer>
    </ScrollContent>
  );
};

export default NewGames;
