
import React from 'react';
import { Container, Title } from './Tournaments.style'; 

const Tournaments = () => {

  return (
    <Container>
      <Title>Coming soon</Title>
    </Container>
  );
};

export default Tournaments;
